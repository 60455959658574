import { Path, Svg } from 'react-native-svg'

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { createIcon } from '../factories/createIcon'

export const [Mobile, AnimatedMobile] = createIcon({
  name: 'Mobile',
  getIcon: (props) => (
    <Svg fill="none" viewBox="0 0 43 43" {...props}>
      <Path
        d="M28.3632 6.11328H14.3633C10.8633 6.11328 9.11328 7.86327 9.11328 11.3633V32.3632C9.11328 35.8632 10.8633 37.6132 14.3633 37.6132H28.3632C31.8632 37.6132 33.6132 35.8632 33.6132 32.3632V11.3633C33.6132 7.86327 31.8632 6.11328 28.3632 6.11328ZM21.3632 34.1132C20.3972 34.1132 19.6132 33.3292 19.6132 32.3632C19.6132 31.3972 20.3972 30.6132 21.3632 30.6132C22.3292 30.6132 23.1132 31.3972 23.1132 32.3632C23.1132 33.3292 22.3292 34.1132 21.3632 34.1132ZM23.9882 12.6758H18.7382C18.0137 12.6758 17.4257 12.0878 17.4257 11.3633C17.4257 10.6388 18.0137 10.0508 18.7382 10.0508H23.9882C24.7127 10.0508 25.3007 10.6388 25.3007 11.3633C25.3007 12.0878 24.7127 12.6758 23.9882 12.6758Z"
        fill="currentColor"
      />
    </Svg>
  ),
})
