import { Currency, CurrencyAmount, TradeType } from "@novaswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { Field } from "components/swap/constants";
import { useConnectionReady } from "connection/eagerlyConnect";
import useAutoSlippageTolerance from "hooks/useAutoSlippageTolerance";
import { useDebouncedTrade } from "hooks/useDebouncedTrade";
import { useSwapTaxes } from "hooks/useSwapTaxes";
import { useUSDPrice } from "hooks/useUSDPrice";
import { Trans } from "i18n";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { ParsedQs } from "qs";
import { ReactNode, useCallback, useContext, useMemo } from "react";
import {
  isClassicTrade,
  isSubmittableTrade,
  isUniswapXTrade,
} from "state/routing/utils";
import { useUserSlippageToleranceWithDefault } from "state/user/hooks";
import { isAddress } from "utilities/src/addresses";

import { TOKEN_SHORTHANDS } from "../../constants/tokens";
import { useCurrencyBalances } from "../connection/hooks";
import {
  CurrencyState,
  SerializedCurrencyState,
  SwapAndLimitContext,
  SwapContext,
  SwapInfo,
  SwapState,
  parseIndependentFieldURLParameter,
} from "./types";

export function useSwapContext() {
  return useContext(SwapContext);
}

export function useSwapAndLimitContext() {
  return useContext(SwapAndLimitContext);
}

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void;
  onSwitchTokens: (options: {
    newOutputHasTax: boolean;
    previouslyEstimatedOutput: string;
  }) => void;
  onUserInput: (field: Field, typedValue: string) => void;
} {
  const { swapState, setSwapState } = useSwapContext();
  const { currencyState, setCurrencyState } = useSwapAndLimitContext();

  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      const [currentCurrencyKey, otherCurrencyKey]: (keyof CurrencyState)[] =
        field === Field.INPUT
          ? ["inputCurrency", "outputCurrency"]
          : ["outputCurrency", "inputCurrency"];
      const otherCurrency = currencyState[otherCurrencyKey];
      // the case where we have to swap the order
      if (otherCurrency && currency.equals(otherCurrency)) {
        setCurrencyState({
          [currentCurrencyKey]: currency,
          [otherCurrencyKey]: currencyState[currentCurrencyKey],
        });
        setSwapState((swapState) => ({
          ...swapState,
          independentField:
            swapState.independentField === Field.INPUT
              ? Field.OUTPUT
              : Field.INPUT,
        }));
      } else {
        setCurrencyState((state) => ({
          ...state,
          [currentCurrencyKey]: currency,
        }));
      }
    },
    [currencyState, setCurrencyState, setSwapState],
  );

  const onSwitchTokens = useCallback(
    ({
      newOutputHasTax,
      previouslyEstimatedOutput,
    }: {
      newOutputHasTax: boolean;
      previouslyEstimatedOutput: string;
    }) => {
      // To prevent swaps with FOT tokens as exact-outputs, we leave it as an exact-in swap and use the previously estimated output amount as the new exact-in amount.
      if (newOutputHasTax && swapState.independentField === Field.INPUT) {
        setSwapState((swapState) => ({
          ...swapState,
          typedValue: previouslyEstimatedOutput,
        }));
      } else {
        setSwapState((prev) => ({
          ...prev,
          independentField:
            prev.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
        }));
      }

      setCurrencyState((prev) => ({
        inputCurrency: prev.outputCurrency,
        outputCurrency: prev.inputCurrency,
      }));
    },
    [setCurrencyState, setSwapState, swapState.independentField],
  );

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      setSwapState((state) => {
        return {
          ...state,
          independentField: field,
          typedValue,
        };
      });
    },
    [setSwapState],
  );

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
  };
}

// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapInfo(state: SwapState): SwapInfo {
  const { account } = useWeb3React();

  const {
    currencyState: { inputCurrency, outputCurrency },
  } = useSwapAndLimitContext();
  const { independentField, typedValue } = state;

  const { inputTax, outputTax } = useSwapTaxes(
    inputCurrency?.isToken ? inputCurrency.address : undefined,
    outputCurrency?.isToken ? outputCurrency.address : undefined,
  );

  const relevantTokenBalances = useCurrencyBalances(
    account ?? undefined,
    useMemo(
      () => [inputCurrency ?? undefined, outputCurrency ?? undefined],
      [inputCurrency, outputCurrency],
    ),
  );

  const isExactIn: boolean = independentField === Field.INPUT;
  const parsedAmount = useMemo(
    () =>
      tryParseCurrencyAmount(
        typedValue,
        (isExactIn ? inputCurrency : outputCurrency) ?? undefined,
      ),
    [inputCurrency, isExactIn, outputCurrency, typedValue],
  );

  const trade = useDebouncedTrade(
    isExactIn ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
    parsedAmount,
    (isExactIn ? outputCurrency : inputCurrency) ?? undefined,
    undefined,
    account,
  );

  const { data: outputFeeFiatValue } = useUSDPrice(
    isSubmittableTrade(trade.trade) && trade.trade.swapFee
      ? CurrencyAmount.fromRawAmount(
          trade.trade.outputAmount.currency,
          trade.trade.swapFee.amount,
        )
      : undefined,
    trade.trade?.outputAmount.currency,
  );

  const currencyBalances = useMemo(
    () => ({
      [Field.INPUT]: relevantTokenBalances[0],
      [Field.OUTPUT]: relevantTokenBalances[1],
    }),
    [relevantTokenBalances],
  );

  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency,
      [Field.OUTPUT]: outputCurrency,
    }),
    [inputCurrency, outputCurrency],
  );

  // allowed slippage for classic trades is either auto slippage, or custom user defined slippage if auto slippage disabled
  const classicAutoSlippage = useAutoSlippageTolerance(
    isClassicTrade(trade.trade) ? trade.trade : undefined,
  );

  // slippage for uniswapx trades is defined by the quote response
  const uniswapXAutoSlippage = isUniswapXTrade(trade.trade)
    ? trade.trade.slippageTolerance
    : undefined;

  // Uniswap interface recommended slippage amount
  const autoSlippage = uniswapXAutoSlippage ?? classicAutoSlippage;
  const classicAllowedSlippage =
    useUserSlippageToleranceWithDefault(autoSlippage);

  // slippage amount used to submit the trade
  const allowedSlippage = uniswapXAutoSlippage ?? classicAllowedSlippage;

  const connectionReady = useConnectionReady();
  const inputError = useMemo(() => {
    let inputError: ReactNode | undefined;

    if (!account) {
      inputError = connectionReady ? (
        <Trans>Connect wallet</Trans>
      ) : (
        <Trans>Connecting wallet...</Trans>
      );
    }

    if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
      inputError = inputError ?? <Trans>Select a token</Trans>;
    }

    if (!parsedAmount) {
      inputError = inputError ?? <Trans>Enter an amount</Trans>;
    }

    // compare input balance to max input based on version
    const [balanceIn, maxAmountIn] = [
      currencyBalances[Field.INPUT],
      trade?.trade?.maximumAmountIn(allowedSlippage),
    ];

    if (balanceIn && maxAmountIn && balanceIn.lessThan(maxAmountIn)) {
      inputError = (
        <Trans>
          Insufficient {{ symbol: balanceIn.currency.symbol }} balance
        </Trans>
      );
    }

    return inputError;
  }, [
    account,
    currencies,
    parsedAmount,
    currencyBalances,
    trade?.trade,
    allowedSlippage,
    connectionReady,
  ]);

  return useMemo(
    () => ({
      currencies,
      currencyBalances,
      parsedAmount,
      inputError,
      trade,
      autoSlippage,
      allowedSlippage,
      outputFeeFiatValue,
      inputTax,
      outputTax,
    }),
    [
      allowedSlippage,
      autoSlippage,
      currencies,
      currencyBalances,
      inputError,
      outputFeeFiatValue,
      parsedAmount,
      trade,
      inputTax,
      outputTax,
    ],
  );
}

function parseCurrencyFromURLParameter(urlParam: ParsedQs[string]): string {
  if (typeof urlParam === "string") {
    const valid = isAddress(urlParam);
    if (valid) return valid;
    const upper = urlParam.toUpperCase();
    if (upper === "ETH") return "ETH";
    if (upper in TOKEN_SHORTHANDS) return upper;
  }
  return "";
}

export function queryParametersToCurrencyState(
  parsedQs: ParsedQs,
): SerializedCurrencyState {
  let inputCurrency = parseCurrencyFromURLParameter(
    parsedQs.inputCurrency ?? parsedQs.inputcurrency,
  );
  let outputCurrency = parseCurrencyFromURLParameter(
    parsedQs.outputCurrency ?? parsedQs.outputcurrency,
  );
  const independentField = parseIndependentFieldURLParameter(
    parsedQs.exactField,
  );

  if (
    inputCurrency === "" &&
    outputCurrency === "" &&
    independentField === Field.INPUT
  ) {
    // Defaults to having the native currency selected
    inputCurrency = "ETH";
  } else if (inputCurrency === outputCurrency) {
    // clear output if identical
    outputCurrency = "";
  }

  return {
    inputCurrencyId:
      inputCurrency === "" ? undefined : inputCurrency ?? undefined,
    outputCurrencyId:
      outputCurrency === "" ? undefined : outputCurrency ?? undefined,
  };
}
